
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class ResultadosCard extends Vue {
    @Prop({ type: String, required: true })
    private title!: string;
    
    @Prop({ type: Number, required: true })
    private percentage!: number;

    @Prop({ type: Number, required: true })
    private questions!: number;

    private get color(): string {
        if(this.percentage <= 45) {
            return '--red';
        }
        else if(this.percentage > 45 && this.percentage < 70) {
            return '--yellow';
        }
        else {
            return '--green';
        }
    }
}

export default ResultadosCard;
