
import { Component, Vue } from 'vue-property-decorator';
import PNavigationCard from '@/components/layouts/p-NavigationCard.vue';
import vClickOutside from 'v-click-outside';

@Component({
    components: { PNavigationCard },
    directives: { 'click-out': vClickOutside.directive },
})
class MeusResultadosPage extends Vue {

    private opened: boolean = false;
    private pageIndex: number = 0;
    private transition: string = 'toLeft';
    private hoverScroll: boolean = false;

    private pages: string[] = [ 'disciplinas', 'categoria-a', 'categoria-b'];

    private navigate(index: number) {
        if(index != this.pageIndex) {
            this.transition = index > this.pageIndex ? 'toLeft' : 'toRight';
            this.$router.replace({ name: this.pages[index] });
            this.pageIndex = index;
        }
    }

    private checkClose() {
        const content = this.$refs.content as HTMLElement;
        if(!this.hoverScroll || content.scrollTop == 0) {
            this.opened = false;
        }
    }
}

export default MeusResultadosPage;
