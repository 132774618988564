
import { Component, Vue } from 'vue-property-decorator';
import PResultadosCard from '@/components/layouts/p-ResultadosCard.vue';

@Component({ components: { PResultadosCard } })
class CategoriaB extends Vue {
    public hasPontuacao = true;
}

export default CategoriaB;
