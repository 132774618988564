
import { Component, Vue } from 'vue-property-decorator';
import PResultadosCard from '@/components/layouts/p-ResultadosCard.vue';
import EstatisticaTeorico from '../../models/EstatisticaTeorico';
import ResultadosIllustration from '@/components/illustrations/resultados.vue';

@Component({ components: { PResultadosCard, ResultadosIllustration } })
class Disciplinas extends Vue {

    private async mounted(){
        await this.$store.dispatch('GetEstatisticasApi')
            .catch(error => {
                this.error = error;
            });
    }

    private get loading() {
        return this.$store.getters['GetLoadingSimulado'];
    }

    private get estatisticas(): EstatisticaTeorico {
        return this.$store.getters['GetEstatisticasSimulado'];
    }

    private error: string = '';
}

export default Disciplinas;
