
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
class PNavigationCard extends Vue {

    @Prop({ type: Boolean, required: true })
    private selected!: boolean;

    @Prop({ type: String, required: true })
    private icon!: string;

    @Prop({ type: String, required: true })
    private caption!: string;

    @Prop({ type: String, default: '18px' })
    private iconSize!: string;
}

export default PNavigationCard;
